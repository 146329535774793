// src/components/Header.js
import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const BasicHeader = ({ title }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="row p-3 bg-light border-bottom shadow-sm">
      <div className="col-2 d-flex justify-content-start align-items-center">
        <button className="btn btn-link" onClick={handleBackClick}>
          <FaArrowLeft size={15} />
        </button>
      </div>
      <div className="col-8 d-flex justify-content-center align-items-center">
        <h2 className="mb-0 custom-heading" dangerouslySetInnerHTML={{ __html: title }}></h2>
      </div>
      <div className="col-2"></div>
    </div>
  );
};

export default BasicHeader;

import React, { useState, useRef, useEffect } from "react";
import "../styles/MobileNumberForm.css";
import Zong from '../models/Zong';

const MobileNumberForm = ({ Initialmsisdn, serviceid, handleChange, error }) => {
  // State variables
  const [mobileNumber, setMobileNumber] = useState(Initialmsisdn || "");
  const [otpSent, setOtpSent] = useState(false);
  const [otpHash, setOtpHash] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [isVerified, setIsVerified] = useState(false);
  const otpRefs = useRef([]);
  const isFirstRender = useRef(true); // ref to track first render


  const zong = new Zong();
  
    const item = zong.getItemById(serviceid);
    console.log(item);  // This will throw an error
 
  // Function to handle number input change
  const handleNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); 
    setMobileNumber(value);
    if (value.length === 11) {
      sendOtp(value);
    } 
    else {
      setOtpSent(false);
      setOtpHash("");
      setOtp(["", "", "", ""]);
      setErrorMessage("");
    }
  };

  // Function to send OTP
  const sendOtp = async (number) => {
    try {
      const formData = new FormData();
      formData.append("msisdn", number);
      formData.append("masking", "9362");
      formData.append("service_name", item.name);

      const response = await fetch("https://digital.webdoc.com.pk/Promotion/public/send-otp", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.code === "0000") {
        setOtpSent(true);
        const otpArray = data.data.otp.split("").map(digit => digit.toString()); 
        //imdesate fill
     // setOtp(otpArray);  // This sets the OTP directly into the OTP input fields

     setTimeout(() => {
      setOtp(otpArray);  // This will fill the OTP input fields after the delay
    }, 4000);  // 4000 milliseconds = 4 seconds

        setOtpHash(data.data.otp_hash);
      } else {
        setErrorMessage("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setErrorMessage(`An error occurred sendotp: ${error.message}`);
    }
  };

  // Function to verify OTP
  const verifyOtp = async () => {
    const userOtp = otp.join("");
    if (userOtp.length !== 4) {
      setErrorMessage("Please enter a valid 4-digit OTP.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("msisdn", mobileNumber);
      formData.append("hash", otpHash);
      formData.append("service_name", item.name);
      formData.append("userOtp", userOtp);
      formData.append("api_service", item.api_service);
      formData.append("serviceid", item.serviceid);
      console.log(otpHash);

      // Track the OTP verification button click event in Google Analytics
      if (typeof window !== "undefined" && window.gtag) {
        window.gtag('event', 'verify_otp', {
          'event_category': 'OTP',
          'event_label': 'User clicked Verify OTP',
          'value': 1
        });
      } else {
        alert("Google Analytics (gtag) is not available.");
        console.log("Google Analytics (gtag) is not available.");
      }

      const response = await fetch("https://digital.webdoc.com.pk/Promotion/public/verify-otp", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.code === "0000") {
        setIsVerified(true); // Switch to success card
      } else if (data.code === "0003") {
        setErrorMessage("OTP has expired. Please resend the OTP.");
      } else {
        setErrorMessage(data.message || "Failed to verify OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setErrorMessage(`An error occurred: ${error.message}`);
    }
  };

  // Function to handle OTP input
  const handleOtpChange = (value, index) => {
    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otpRefs.current.length - 1) {
        otpRefs.current[index + 1].focus();
      }
    }
  };

  // Function to handle backspace
  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      otpRefs.current[index - 1].focus();
    }
  };

  // Effect to handle OTP sending on page load if mobileNumber is provided
  useEffect(() => {
    // Only run once on the first render if `mobileNumber` is 11 digits
    if (isFirstRender.current) {
      isFirstRender.current = false;
      if (mobileNumber.length === 11 && !otpSent) {
        sendOtp(mobileNumber);
      }
    }
  }, [mobileNumber, otpSent]); // Effect only triggers when mobileNumber or otpSent changes

  return (
    <div className="container d-flex justify-content-center align-items-center mobilecontainer mt-2">
      {/* Conditional rendering: Show success card or input card */}
      {isVerified ? (
        <div className="card-input rounded-border text-center p-4 bg-light mb-5">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Check_green_icon.svg/1024px-Check_green_icon.svg.png"
            alt="Success"
            className="mb-3"
            style={{ width: "80px", height: "80px" }}
          />
          <h4 className="fw-bold text-success">Package Subscribed Successfully!</h4>
          <p className="text-muted mt-2">Thank you for subscribing to our {item.successtext}</p>
        </div>
      ) : (
        <div className="card-input rounded-border mb-5">
          <p className="text-center fw-bold">Enter Your Mobile Number</p>
          <div className="row justify-content-center mt-3">
            <div className="col-md-10 col-sm-12">
              <div className="input-group input-group-style">
                <div className="input-group-prepend">
                  <span className="input-group-text">+92</span>
                </div>
                <input
                  type="text"
                  placeholder="03XXXXXXXXX"
                  className="form-control input-field"
                  value={mobileNumber}
                  onChange={handleNumberChange}
                  maxLength={11}
                />


              </div>
              <p className="mt-2 text-muted text-center mobile-field-text">
                {item.text}
              </p>
              {/* Error message */}
              {errorMessage && <p className="mt-2 text-danger text-center">{errorMessage}</p>}

              {/* OTP Input Fields */}
              {otpSent && (
                <div className="mt-3 text-center">
                  <p className="fw-bold">Enter OTP to Verify</p>
                  <div className="d-flex justify-content-center gap-2">
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        type="number"
                        maxLength={1}
                        className="otp-input"
                        value={digit}
                        onChange={(e) => handleOtpChange(e.target.value, index)}
                        onKeyDown={(e) => handleOtpKeyDown(e, index)}
                        ref={(el) => (otpRefs.current[index] = el)}
                      />
                    ))}
                  </div>
                  <button className="btn btn-danger mt-3 p-3" onClick={verifyOtp}>
                    Verify OTP
                  </button>
                  <br />
                  <br />
                  {/* <button className="btn btn-link text-danger mt-2">Resend OTP</button> */}
                </div>
              )}

             
              <a href="/privacy-policy" className="text-danger fw-bold text-center d-block">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileNumberForm;

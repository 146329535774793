import React from "react";
import "../styles/Header.css"
const Header = () => {
  return (

    <header className="text-center py-2">
              <img
            src="/images/webdoclogo.webp" // Replace with your image path
            alt="Webdoclogo"
            className="webdoclogo"

          />
        
    </header>
  );
};

export default Header;

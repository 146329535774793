class Zong {
    constructor() {
      this.items = [
        {
            id: "1",
            name: "Health Insurance",
            text: "Health Insurance Only For Rs. 3 + Tax daily",
            image: "/images/Zong/HealthIn.webp",
            price:"3",
            successtext: "Helath Insurance For Rs. 3 + Tax daily",
            privacypolicy: "Mobile Insurance For Rs. 3 + Tax daily",
            serviceid: "1",
            api_service:"API_HIS"
        },
        {
            id: "2",
            name: "Mobile Insurance",
            text: "Mobile Insurance Only For Rs. 3.8 + Tax daily",
            image: "/images/Zong/MobileIn.webp",
            price:"3.8",
            successtext: "Mobile Insurance For Rs. 3.8 + Tax daily",
            privacypolicy: "",
            serviceid: "1",
            api_service:"API_MIS"
        },
        
      ];
    }
  
    getAllItems() {
      return this.items;
    }
    getItemById(id) {
        const item = this.items.find(i => i.id === id);
        if (!item) {
          return `Item with id ${id} not found.`; // Custom error message
        }
        return item;
      }
  }
  
  export default Zong;
  
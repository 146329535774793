import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom'; 
import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import MobileNumberForm from "../components/MobileNumberForm";
import Footer from "../components/Footer";
import CryptoJS from "crypto-js";

const Dashboard = () => {
  const [msisdn, setMsisdn] = useState(""); // State to hold the decrypted msisdn
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(""); // Error state
  const { serviceidtest }=useParams();  
  const backgroundImages = {
    1: '/images/Zong/HealthIn.webp', // Replace with the actual path
    2: '/images/Zong/MobileIn.webp'
    // Add more mappings as needed
  };
  
  const serviceid = serviceidtest || '1';


  // Function to handle msisdn input changes
  const handleChange = (event) => {
  
    //setInput(cleanedInput);
    setMsisdn(event.target.value); // Update msisdn state
    setError(""); // Clear any existing errors
  };

  const decryptData = (encryptedData, key) => {
    const decryptedBase64 = CryptoJS.enc.Base64.parse(encryptedData);
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: decryptedBase64 },
      CryptoJS.enc.Utf8.parse(key),
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.NoPadding,
      }
    );
    //const cleanedInput = rawInput.replace(/[\r\n\s]+/g, '');
    return decrypted.toString(CryptoJS.enc.Utf8).replace(/[\r\n\s]+/g, ''); // Remove all spaces
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("http://115.42.72.186:8088/HE/test.php"); // API URL
       // const response = await fetch("http://115.42.72.186:8088/HE/index.php"); // API URL
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
  
        const result = await response.json();
        console.log(result);
  
        // Assume result contains encrypted msisdn
        const encryptedMsisdn = result.msisdn;
        const key = "dacq@11223344556";
  
        // Decrypt msisdn
        const decryptedMsisdn = decryptData(encryptedMsisdn, key); // Spaces already removed
        const formattedMsisdn = decryptedMsisdn.startsWith("92")
          ? "0" + decryptedMsisdn.substring(2) // Add "0" if it starts with "92"
          : decryptedMsisdn;

        setMsisdn(formattedMsisdn.trim().replace(/[^\x20-\x7E]/g, ''));

        console.log(formattedMsisdn); // Output without spaces
        console.log(decryptedMsisdn); // Output without spaces
      } catch (error) {
        console.error("Error fetching or decrypting msisdn:", error);
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      <Header />
      <HeroSection imagelink={backgroundImages[serviceid]}/>
      <MobileNumberForm Initialmsisdn={msisdn} serviceid={serviceid} handleChange={handleChange} error={error} />
    </div>
  );
};

export default Dashboard;

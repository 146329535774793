import React from "react";
import "../styles/HeroSection.css"
import { ImPagebreak } from "react-icons/im";

const HeroSection = ({imagelink}) => {

  return (
    <div className="container text-center">
      <div className="row justify-content-center">
        <div className="col-md-6 col-sm-8">
          <img
            src={imagelink} // Replace with your image path
            alt="Family"
            className="img-fluid rounded insuranceimg"
            width="345"
            height="333"
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
